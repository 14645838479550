import api from "@/axios";

export default class ProdutoService {

    getProdutos() {
        return api.get('api/ssprodutoindex' ).then((res) => res.data);
    }

    getProduto(id) {
        const params = { id }
        return api.get('api/ssprodutoget', {params}).then((res) => res.data);
    }

    saveProduto(produto) {
        return api.post('api/ssprodutosave', {produto} ).then((res) => res.data);
    }

    deleteProduto(id) {
        const params = { id }
        return api.get('api/ssprodutodel', { params }).then((res) => res.data);
    }

}
