<template>
  <div>
    <div class="grid crud-demo">
      <div class="col-12">
        <div class="card">
          <h6 class="">Administração de Produtos</h6>
          <Toast/>
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">
                <Button label="Novo Produto" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" :disabled="true" v-if="userData && userData.tipo === 'admin'"/>
              </div>
            </template>

            <template v-slot:end>
              <FileUpload mode="basic" accept=".pdf" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 p-button-outlined" :disabled="true" v-if="userData && userData.tipo === 'admin'"/>
              <Button label="Exportar" icon="pi pi-download" class="p-button-outlined" @click="exportCSV($event)" :disabled="true" v-if="userData && userData.tipo === 'admin'"/>
            </template>
          </Toolbar>

          <DataTable ref="dt" :value="produtos" dataKey="id" :paginator="true" :rows="10"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} produtos" responsiveLayout="scroll">
            <template #header>
              <div class="flex justify-content-between flex-column sm:flex-row">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
              </div>
            </template>

            <Column field="id" header="ID" :sortable="true" filterField="id" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Id</span>
                {{slotProps.data.id}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
              </template>
            </Column>

            <Column field="codexterno" header="Código" :sortable="true" filterField="codexterno" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Nome</span>
                {{slotProps.data.codexterno}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo produto"/>
              </template>
            </Column>

            <Column field="descricao" header="Descrição" :sortable="true" class="text-left" filterField="descricao" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Descrição</span>
                {{formatCurrency(slotProps.data.descricao)}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela descrição"/>
              </template>
            </Column>

            <Column field="unidade" header="Unidade" :sortable="true" class="text-right" filterField="unidade" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Unidade</span>
                {{slotProps.data.unidade}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela unidade"/>
              </template>
            </Column>

            <Column field="status" header="Status" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Status</span>
                <span :class="'produto-badge status-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">{{ nomeStatus(slotProps.data.status) }}</span>
              </template>
            </Column>

            <Column header="Último Pedido" dataType="date" style="min-width:10rem">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>

            <Column field="preco" header="Preço" class="text-right" :sortable="true" filterField="preco" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Valor</span>
                {{formatCurrency(slotProps.data.preco)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
              </template>
            </Column>


            <Column header="Ações" class="text-right">
              <template #body="slotProps">
<!--                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2" @click="confirmDeleteProduto(slotProps.data)" />-->
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduto(slotProps.data)" />
                <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2" @click="visibleTimeline = true" />
              </template>
            </Column>
          </DataTable>


        </div>
      </div>


    </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-6">
        <div class="card">
          <h6>Evolução Valor Mensal</h6>
          <Chart type="bar" :data="barData" :options="barOptions"></Chart>
        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="card flex flex-column align-items-center">
          <h6>Status</h6>
          <Chart type="doughnut" :data="pieData" :options="pieOptions" style="position:relative; width: 50%"></Chart>
        </div>
      </div>
    </div>


    <div class="col-12 lg:col-12">
      <Sidebar v-model:visible="visibleTimeline" :baseZIndex="1000" position="right">
        <h3 style="font-weight: normal">Timeline</h3>

      </Sidebar>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import ProdutoService from '@/service/ProdutoService';



export default {
  components: {


  },
  data() {
    return {
      userData: null,
      visibleTimeline: false,
      loading1: true,
      filters1: null,
      produtos: null,

      produto: {},

      submitted: false,
      statuses: [
        {label: 'QUALIFICADO', value: 'qualificado'},
        {label: 'ANÁLISE', value: 'análise'},
        {label: 'DESQUALIFICADO', value: 'desqualificado'}
      ],

      categorias: [
        {name: "Rural" },
        {name: "Residencial" },
        {name: "Grandes Negócios" },
      ],

      barData: {},
      barOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },

      pieData: {},
      pieOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        }
      },

    }
  },
  produtoService: null,
  created() {
    this.produtoService = new ProdutoService();
    this.initFilters();
  },
  mounted() {


    this.produtoService.getProdutos().then((data) => {
      console.log('produtosList mounted')
      this.loading1 = false
      this.userData = data[0]
      this.produtos = data[1]

      this.produtos.forEach((produto) => {
        produto.date = new Date(produto.date)
      });

      this.barData ={
        labels: data[2],
        datasets: [
          {
            label: 'Elaboração',
            backgroundColor: '#FEEDAF',
            borderColor: '#8A5340',
            data: data[3]
          },
          {
            label: 'Confirmado',
            backgroundColor: '#C8E6C9',
            borderColor: '#256029',
            data: data[4]
          }
        ]
      }

      this.pieData = {
        labels: data[5],
        datasets: [
          {
            data: data[6],
            backgroundColor: [
              'rgb(54, 162, 235)',
              'rgb(255, 99, 132)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)'
            ]
          }]
      }


    })


  },
  methods: {
    nomeStatus(sigla){
      switch (sigla) {
        case 'A':
          return 'Ativo'
        case 'I':
          return 'Inativo'
        case 'E':
          return 'Elaboração'
        default:
          return 'Não Definido'
      }
    },

    openNew() {
      this.$router.push({ path: '/produtodetalhe/0' });
    },

    editProduto(produto) {
      this.$router.push({ path: `/produtodetalhe/${produto.id}` });
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    initFilters() {
      this.filters1 = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'codexterno': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'descricao': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'unidade': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'preco': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},

      }

    },

    clearFilter1() {
      this.initFilters();
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return '';
    },

    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return '';
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
